import Vue from "vue";
import money from "v-money";
import PrimeVue from "primevue/config";

import store from "@/store";
import router from "@/router";
import i18n from "@/i18n";

import "@/plugins/primevue";
import "@/assets/index.scss";

import App from "@/app.vue";
import "./registerServiceWorker";

Vue.use(money);
Vue.use(PrimeVue, {
  locale: i18n.primevue.locale,
  ripple: true,
});
Vue.config.productionTip = false;

Vue.directive("uppercase", {
  inserted(element: any, _, vNode: any) {
    element.addEventListener("input", (event: any) => {
      event.target.value = event.target.value.toUpperCase();
      vNode.componentInstance.$emit("input", event.target.value);
    });
  },
});

new Vue({ router, store, render: (h) => h(App) }).$mount("#app");
