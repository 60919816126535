<template>
  <p-dialog
    :class="componentCSS"
    :visible.sync="show"
    :closable="modal.options.closable"
    :style="modal.options.style.modal"
    modal
  >
    <template #header v-if="modal.options.useHeader">
      <h3 v-if="modal.content.title" v-text="modal.content.title"></h3>
    </template>

    <c-modal-file-upload
      v-if="modalIs('modal-file-upload')"
      ref="modal-file-upload"
      :props="modal.props"
      @upload="onAccept"
    />
    <c-import-pauta v-if="modalIs('import-pauta')" ref="import-pauta" :props="modal.props" @upload="onAccept" />
    <c-modal-kanban v-else-if="modalIs('modal-kanban')" ref="modal-kanban" :props="modal.props" />
    <c-modal-excel-logistics
      v-else-if="modalIs('modal-excel-logistics')"
      ref="modal-excel-logistics"
      :props="modal.props"
    />
    <c-modal-excel-lawsuit v-else-if="modalIs('modal-excel-lawsuit')" ref="modal-excel-lawsuit" :props="modal.props" />
    <c-modal-excel-task v-else-if="modalIs('modal-excel-task')" ref="modal-excel-task" :props="modal.props" />
    <c-modal-excel-debtor v-else-if="modalIs('modal-excel-debtor')" ref="modal-excel-debtor" :props="modal.props" />
    <c-task-reschedule v-else-if="modalIs('task-reschedule')" ref="task-reschedule" :props="modal.props" />
    <c-task-distribution v-else-if="modalIs('task-distribution')" ref="task-distribution" :props="modal.props" />
    <c-task-responsible v-else-if="modalIs('task-responsible')" ref="task-responsible" :props="modal.props" />
    <c-task-team v-else-if="modalIs('task-team')" ref="task-team" :props="modal.props" />
    <c-act-type v-else-if="modalIs('act-type')" ref="act-type" :props="modal.props" />
    <c-update-process-stage
      v-else-if="modalIs('update-process-stage')"
      ref="update-process-stage"
      :props="modal.props"
    />
    <c-modal-pauta v-else-if="modalIs('modal-pauta')" ref="modal-pauta" :props="modal.props" />
    <c-modal-updates v-else-if="modalIs('modal-updates')" ref="modal-updates" :props="modal.props" />
    <c-excel-expedient v-else-if="modalIs('modal-expedient')" ref="modal-expedient" :props="modal.props" />
    <c-filter-diligence
      v-else-if="modalIs('modal-filter-diligence')"
      ref="modal-filter-diligence"
      :props="modal.props"
    />
    <c-modal-refresh-token
      v-else-if="modalIs('refresh-token')"
      ref="refresh-token"
      :props="modal.props"
      @submit="onAccept"
    />
    <c-request-conference
      v-else-if="modalIs('request-conference')"
      ref="request-conference"
      :props="modal.props"
      @submit="onAccept"
    />
    <c-text-view v-else-if="modalIs('text-view')" ref="text-view" :props="modal.props" />
    <c-task-form
      v-else-if="modalIs('task-form')"
      ref="task-form"
      :props="modal.props"
      @submit="onAccept"
      @close="onRecuse"
    />
    <c-input-text-modal v-else-if="modalIs('input-text')" ref="input-text" :props="modal.props" @submit="onAccept" />
    <c-text-area-modal v-else-if="modalIs('text-area')" ref="text-area" :props="modal.props" @submit="onAccept" />
    <c-task-finish-modal v-else-if="modalIs('task-finish')" ref="task-finish" :props="modal.props" @submit="onAccept" />
    <c-input-money-modal v-else-if="modalIs('input-money')" ref="input-money" :props="modal.props" @submit="onAccept" />
    <c-date-time-modal v-else-if="modalIs('date-time')" ref="date-time" :props="modal.props" @submit="onAccept" />
    <c-pay-parcel-modal v-else-if="modalIs('pay-parcel')" ref="pay-parcel" :props="modal.props" @submit="onAccept" />
    <c-end-lawsuit-modal
      v-else-if="modalIs('end-lawsuit')"
      ref="end-lawsuit"
      :props="modal.props"
      @click-back="onRecuse"
      @submit="onAccept"
    />
    <c-modal-lawsuit-preview v-else-if="modalIs('lawsuit-preview')" ref="lawsuit-preview" :props="modal.props" />
    <c-liminar-modal v-else-if="modalIs('liminar')" ref="liminar" :props="modal.props" @submit="onAccept" />
    <c-move-modal v-else-if="modalIs('move')" ref="move" :props="modal.props" @submit="onAccept" />
    <c-agreement-list-modal
      v-else-if="modalIs('agreement-list')"
      ref="agreement-list"
      :props="modal.props"
      @submit="onAccept"
    />
    <c-agreement-form-modal
      v-else-if="modalIs('agreement-form')"
      ref="agreement-form"
      :props="modal.props"
      @submit="onAccept"
    />
    <c-agreement-holder-modal
      v-else-if="modalIs('agreement-holder')"
      ref="agreement-holder"
      :props="modal.props"
      @submit="onAccept"
    />
    <c-parcel-list-modal v-else-if="modalIs('parcel-list')" ref="parcel-list" :props="modal.props" @submit="onAccept" />
    <c-report-modal v-else-if="modalIs('report')" ref="report" :props="modal.props" @submit="onAccept" />
    <c-team-form v-else-if="modalIs('team-form')" ref="team-form" :props="modal.props" @submit="onAccept" />
    <c-responsible-form
      v-else-if="modalIs('responsible-group')"
      ref="responsible-group"
      :props="modal.props"
      @submit="onAccept"
    />
    <c-loading-modal v-else-if="modalIs('loading-modal')" ref="loading-modal" :props="modal.props" @submit="onAccept" />

    <div class="content-text" :style="modal.options.style.content" v-else>
      <span v-if="modal.options.isHTML" v-html="modal.content.text"></span>
      <span v-else>{{ modal.content.text }}</span>
    </div>

    <template #footer v-if="modal.options.useFooter">
      <p-button
        :class="modal.recuse.class"
        :label="modal.recuse.text"
        :icon="modal.recuse.icon || 'fas fa-times'"
        v-if="modal.options.useRecuse"
        @click="onRecuse"
      />

      <p-button
        :class="modal.accept.class"
        :label="modal.accept.text"
        :icon="loading ? 'pi pi-spin pi-spinner' : modal.accept.icon || 'fas fa-check'"
        autofocus
        @click="onAccept"
      />
    </template>
  </p-dialog>
</template>

<script lang="ts">
import { Component, Ref, Vue, Watch } from "vue-property-decorator";

import CModalRefreshToken from "@/components/modal/refreshtoken.vue";
import CRequestConference from "@/components/modal/requestconference.vue";
import CInputTextModal from "@/components/modal/inputtext.vue";
import CInputMoneyModal from "@/components/modal/inputmoney.vue";
import CDateTimeModal from "@/components/modal/datetime.vue";
import CTextAreaModal from "@/components/modal/textarea.vue";
import CTaskFinishModal from "@/components/modal/task/finish.vue";
import CEndLawsuitModal from "@/components/modal/endlawsuit.vue";
import CModalLawsuitPreview from "@/components/modal/lawsuit-preview.vue";
import CLiminarModal from "@/components/modal/liminar.vue";
import CMoveModal from "@/components/modal/move.vue";
import CAgreementListModal from "@/components/modal/agreement/list.vue";
import CAgreementFormModal from "@/components/modal/agreement/form.vue";
import CAgreementHolderModal from "@/components/modal/agreement/holder.vue";
import CParcelListModal from "@/components/modal/agreement/parcel.vue";
import CReportModal from "@/components/modal/report.vue";
import CTextView from "@/components/modal/text-view.vue";
import CTeamForm from "@/components/modal/teamform.vue";
import CTaskForm from "@/components/modal/task/form.vue";
import CTaskReschedule from "@/components/modal/task/reschedule.vue";
import CTaskDistribution from "@/components/modal/task/distribution.vue";
import CTaskResponsible from "@/components/modal/task/responsible.vue";
import CTaskTeam from "@/components/modal/task/team.vue";
import CActType from "@/components/modal/acttype.vue";
import CModalFileUpload from "@/components/modal/fileupload.vue";
import CModalKanban from "@/components/modal/kanban.vue";
import CModalExcelLogistics from "@/components/modal/excel/logistics.vue";
import CModalExcelLawsuit from "@/components/modal/excel/lawsuit.vue";
import CModalExcelTask from "@/components/modal/excel/task.vue";
import CModalExcelDebtor from "@/components/modal/excel/debtor.vue";
import CModalPauta from "@/components/modal/pauta.vue";
import CUpdateProcessStage from "@/components/modal/update-process-stage.vue";
import CImportPauta from "@/components/modal/importpauta.vue";
import CModalUpdates from "@/components/modal/updates.vue";
import CExcelExpedient from "@/components/modal/excel/expedient.vue";
import CPayParcelModal from "@/components/modal/agreement/pay-parcel.vue";
import CFilterDiligence from "@/components/modal/filtrer-diligence.vue";
import CResponsibleForm from "@/components/modal/responsiblegroup.vue";
import CLoadingModal from "@/components/modal/loading.vue";

import { ModalComponent } from "@/store/global/types";

type modalRef = { getFields: Function; reset: Function; validate?: Function };

@Component({
  components: {
    CModalRefreshToken,
    CRequestConference,
    CInputTextModal,
    CInputMoneyModal,
    CActType,
    CDateTimeModal,
    CTextAreaModal,
    CTaskFinishModal,
    CEndLawsuitModal,
    CModalLawsuitPreview,
    CLiminarModal,
    CMoveModal,
    CAgreementListModal,
    CAgreementFormModal,
    CAgreementHolderModal,
    CTaskForm,
    CParcelListModal,
    CReportModal,
    CTextView,
    CTeamForm,
    CTaskReschedule,
    CTaskDistribution,
    CTaskTeam,
    CTaskResponsible,
    CModalPauta,
    CModalUpdates,
    CExcelExpedient,
    CModalFileUpload,
    CModalKanban,
    CImportPauta,
    CModalExcelLogistics,
    CModalExcelLawsuit,
    CModalExcelTask,
    CModalExcelDebtor,
    CFilterDiligence,
    CResponsibleForm,
    CPayParcelModal,
    CLoadingModal,
    CUpdateProcessStage,
  },
})
export default class CModal extends Vue {
  @Watch("show")
  onUpdateShow(value: boolean) {
    if (value === false) this.loading = false;
  }

  @Ref("refresh-token") readonly refreshToken!: modalRef;
  @Ref("request-conference") readonly requestConference!: modalRef;
  @Ref("input-text") readonly inputText!: modalRef;
  @Ref("act-type") readonly actType!: modalRef;
  @Ref("input-money") readonly inputMoney!: modalRef;
  @Ref("date-time") readonly dateTime!: modalRef;
  @Ref("pay-parcel") readonly payParcel!: modalRef;
  @Ref("task-finish") readonly taskFinish!: modalRef;
  @Ref("text-area") readonly textArea!: modalRef;
  @Ref("end-lawsuit") readonly endLawsuit!: modalRef;
  @Ref("liminar") readonly liminar!: modalRef;
  @Ref("move") readonly move!: modalRef;
  @Ref("agreement-list") readonly agreementList!: modalRef;
  @Ref("agreement-form") readonly agreementForm!: modalRef;
  @Ref("agreement-holder") readonly agreementHolder!: modalRef;
  @Ref("parcel-list") readonly parcelList!: modalRef;
  @Ref("report") readonly report!: modalRef;
  @Ref("team-form") readonly teamForm!: modalRef;
  @Ref("task-form") readonly taskForm!: modalRef;
  @Ref("task-reschedule") readonly taskReschedule!: modalRef;
  @Ref("task-distribution") readonly taskDistribution!: modalRef;
  @Ref("task-responsible") readonly taskResponsible!: modalRef;
  @Ref("task-team") readonly taskTeam!: modalRef;
  @Ref("modal-pauta") readonly exportPauta!: modalRef;
  @Ref("import-pauta") readonly importPauta!: { getFile: () => File; getCustomerID: () => number };
  @Ref("modal-updates") readonly exportUpdates!: modalRef;
  @Ref("modal-expedient") readonly excelExpedient!: modalRef;
  @Ref("update-process-stage") readonly updateProcessStage!: modalRef;
  @Ref("modal-filter-diligence") readonly filterDiligence!: modalRef;
  @Ref("modal-file-upload") readonly modalFileUpload!: modalRef;
  @Ref("responsible-group") readonly responsibleGroup!: modalRef;
  @Ref("loading-modal") readonly loadingModal!: modalRef;

  loading = false;

  get modalIs() {
    return (modal: string) => this.modal.options.modal === modal;
  }

  get componentCSS() {
    return ["c-modal", !this.modal.options.useHeader ? "remove-header" : "", this.modal.options.modal];
  }

  get show() {
    return this.$store.state.global.modal.show;
  }

  set show(value) {
    this.loading = false;
    this.$store.commit("global/updateModalStatus", false);
  }

  get modal(): ModalComponent {
    return this.$store.state.global.modal;
  }

  getParams(eventType: "accept" | "recuse") {
    if (this.modal.options.modal === "refresh-token") {
      const params = this.refreshToken.getFields();
      this.refreshToken.reset();

      return params;
    } else if (this.modal.options.modal === "request-conference") {
      const params = this.requestConference.getFields();
      this.requestConference.reset();

      return params;
    } else if (this.modal.options.modal === "task-form") {
      const params = this.taskForm.getFields();
      this.taskForm.reset();

      return params;
    } else if (this.modal.options.modal === "task-reschedule") {
      const params = this.taskReschedule.getFields();
      this.taskReschedule.reset();

      return params;
    } else if (this.modal.options.modal === "task-distribution") {
      const params = this.taskDistribution.getFields(eventType === "recuse");
      if (eventType === "accept" && params.hasError) return { hasError: true };

      this.taskDistribution.reset();

      return params;
    } else if (this.modal.options.modal === "task-responsible") {
      const params = this.taskResponsible.getFields();
      this.taskResponsible.reset();

      return params;
    } else if (this.modal.options.modal === "task-team") {
      const params = this.taskTeam.getFields();
      this.taskTeam.reset();

      return params;
    } else if (this.modal.options.modal === "act-type") {
      const params = this.actType.getFields();
      this.actType.reset();

      return params;
    } else if (this.modal.options.modal === "input-text") {
      const params = this.inputText.getFields();
      this.inputText.reset();

      return params;
    } else if (this.modal.options.modal === "input-money") {
      const params = this.inputMoney.getFields();
      this.inputMoney.reset();

      return params;
    } else if (this.modal.options.modal === "date-time") {
      const params = this.dateTime.getFields();
      this.dateTime.reset();

      return params;
    } else if (this.modal.options.modal === "pay-parcel") {
      const params = this.payParcel.getFields();
      this.payParcel.reset();

      return params;
    } else if (this.modal.options.modal === "text-area") {
      if (eventType === "recuse") return;

      const params = this.textArea.getFields();
      if (params.hasError) return { hasError: true };
      this.textArea.reset();

      return params;
    } else if (this.modal.options.modal === "task-finish") {
      if (eventType === "recuse") return;

      const params = this.taskFinish.getFields();
      if (params.hasError) return { hasError: true };
      this.taskFinish.reset();

      return params;
    } else if (this.modal.options.modal === "end-lawsuit") {
      const params = this.endLawsuit.getFields();
      this.endLawsuit.reset();

      return params;
    } else if (this.modal.options.modal === "liminar") {
      const params = this.liminar.getFields();
      if (eventType === "accept" && params.hasError) return { hasError: true };
      this.liminar.reset();

      return params;
    } else if (this.modal.options.modal === "move") {
      const params = this.move.getFields();
      this.move.reset();

      return params;
    } else if (this.modal.options.modal === "agreement-list") {
      const params = this.agreementList.getFields();
      this.agreementList.reset();

      return params;
    } else if (this.modal.options.modal === "parcel-list") {
      const params = this.parcelList.getFields();
      this.parcelList.reset();

      return params;
    } else if (this.modal.options.modal === "agreement-form") {
      if (eventType === "accept" && this.agreementForm.validate && !this.agreementForm.validate())
        return { hasError: true };

      return this.agreementForm.getFields();
    } else if (this.modal.options.modal === "agreement-holder") {
      const params = this.agreementHolder.getFields();
      this.agreementHolder.reset();

      return params;
    } else if (this.modal.options.modal === "report") {
      const params = this.report.getFields();
      this.report.reset();

      return params;
    } else if (this.modal.options.modal === "team-form") {
      const params = this.teamForm.getFields();
      this.teamForm.reset();

      return params;
    } else if (this.modal.options.modal === "responsible-group") {
      const params = this.responsibleGroup.getFields();
      this.responsibleGroup.reset();

      return params;
    } else if (this.modal.options.modal === "modal-pauta") {
      const params = this.exportPauta.getFields();
      this.exportPauta.reset();

      return params;
    } else if (this.modal.options.modal === "modal-updates") {
      const params = this.exportUpdates.getFields();
      this.exportUpdates.reset();

      return params;
    } else if (this.modal.options.modal === "modal-expedient") {
      const params = this.excelExpedient.getFields();
      this.excelExpedient.reset();

      return params;
    } else if (this.modal.options.modal === "modal-filter-diligence") {
      const params = this.filterDiligence.getFields();
      this.filterDiligence.reset();

      return params;
    } else if (this.modal.options.modal === "modal-file-upload") {
      const params = this.modalFileUpload.getFields();
      this.modalFileUpload.reset();

      return params;
    }
    if (this.modal.options.modal === "update-process-stage") {
      const params = this.updateProcessStage.getFields();
      this.updateProcessStage.reset();

      return params;
    } else if (this.modal.options.modal === "import-pauta") {
      return { file: this.importPauta.getFile(), cd_cliente: this.importPauta.getCustomerID() };
    } else if (this.modal.options.modal === "loading-modal") {
      const params = this.loadingModal.getFields();
      this.loadingModal.reset();

      return params;
    }

    return {};
  }

  onAccept() {
    if (this.modal.options.asyncClose) this.loading = true;
    const params = this.getParams("accept");

    if (!params?.hasError) this.modal.accept.function(params);
  }

  onRecuse() {
    this.modal.recuse.function(this.getParams("recuse"));
  }
}
</script>

<style lang="scss">
.c-modal {
  color: #495057;
  background-color: #fff;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px #0000004d;
    background-color: #fcfcfc;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #fcfcfc;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #3273dc;
  }

  .p-dialog-header,
  .p-dialog-content,
  .p-dialog-footer {
    color: inherit;
    background-color: inherit;
  }

  .p-dialog-content {
    overflow: unset;
  }

  .content-text {
    font-size: 0.95rem;
    line-height: 1.4;

    p + p {
      margin-top: 1rem;
    }

    ol,
    ul {
      padding-top: 1rem;
      padding-left: 1.5rem;
    }

    ol li {
      list-style: disc;
    }

    ul li {
      list-style: numeric;
    }
  }

  > .form {
    width: 100%;
  }

  &.remove-header {
    margin: 0 1%;

    > .p-dialog-content {
      padding: 0;
      border-radius: 4px;
      height: 100%;

      overflow: hidden;
    }

    > .p-dialog-header {
      display: none;
    }
  }

  &.default {
    margin: 0 0.5rem;

    @media only screen and (max-width: 380px) {
      .p-dialog-header {
        h3 {
          font-size: 1.125rem;
        }
      }

      .p-dialog-content {
        font-size: 0.95rem;
      }

      .p-dialog-footer {
        display: flex;
        flex-direction: column-reverse;
        gap: 0.25rem;

        .p-button {
          width: 100%;
        }
      }
    }
  }
}
</style>
