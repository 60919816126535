import { RouteConfig } from "vue-router";

const routes: RouteConfig = {
  path: "/user",
  name: "user",
  component: () => import("@/layouts/user.vue"),
  redirect: () => ({ name: "user-login" }),
  children: [
    {
      path: "/user/login",
      name: "user-login",
      component: () => import("@/views/user/login.vue"),
    },
    {
      path: "/user/forgot-password",
      name: "user-forgot-password",
      component: () => import("@/views/user/forgotpassword.vue"),
    },
    {
      path: "/forgot-password",
      name: "user-reset-password",
      component: () => import("@/views/user/resetpassword.vue"),
    },
    {
      path: "/user/register",
      name: "user-register",
      component: () => import("@/views/user/register.vue"),
      redirect: { name: "user-register-correspondent" },
      children: [
        {
          path: "/user/register/correspondente",
          name: "user-register-correspondent",
          component: () => import("@/views/user/register-correspondent.vue"),
        },
        {
          path: "/user/register/escritorio",
          name: "user-register-law-firm",
          component: () => import("@/views/user/register-law-firm.vue"),
        },
      ],
    },
  ],
};

export default routes;
