import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/dashboard/financeiro/geral",
    name: "financial",
    redirect: { name: "financial-bill-to-pay" },
    component: () => import("@/views/financial/list.vue"),
    children: [
      {
        path: "/dashboard/financeiro/geral/recebimentos",
        name: "financial-bill-to-receive",
        component: () => import("@/views/financial/bill-to-receive/list.vue"),
      },
      {
        path: "/dashboard/financeiro/geral/pagamentos",
        name: "financial-bill-to-pay",
        component: () => import("@/views/financial/bill-to-pay/list.vue"),
      },
      {
        path: "/dashboard/financeiro/geral/transferencias",
        name: "financial-transfer",
        component: () => import("@/views/financial/transfer/list.vue"),
      },
    ],
  },
  {
    path: "/dashboard/financeiro/categorias",
    name: "financial-category",
    component: () => import("@/views/financial/category/list.vue"),
  },
  {
    path: "/dashboard/financeiro/contas-bancarias",
    name: "financial-bank-account",
    component: () => import("@/views/financial/bank-account/list.vue"),
  },
  {
    path: "/dashboard/financeiro/centros-de-custo",
    name: "financial-cost-center",
    component: () => import("@/views/financial/cost-center/list.vue"),
  },
  {
    path: "/dashboard/financeiro/fornecedores",
    name: "financial-favored",
    component: () => import("@/views/financial/favored/list.vue"),
  },
  {
    path: "/dashboard/financeiro/recebimentos/adicionar",
    name: "financial-bill-to-receive-add",
    component: () => import("@/views/financial/bill-to-receive/form.vue"),
  },
  {
    path: "/dashboard/financeiro/recebimentos/:cd",
    name: "financial-bill-to-receive-details",
    component: () => import("@/views/financial/bill-to-receive/details.vue"),
  },
  {
    path: "/dashboard/financeiro/recebimentos/:cd/editar",
    name: "financial-bill-to-receive-edit",
    component: () => import("@/views/financial/bill-to-receive/form.vue"),
  },
  {
    path: "/dashboard/financeiro/pagamentos/adicionar",
    name: "financial-bill-to-pay-add",
    component: () => import("@/views/financial/bill-to-pay/form.vue"),
  },
  {
    path: "/dashboard/financeiro/pagamentos/:cd",
    name: "financial-bill-to-pay-details",
    component: () => import("@/views/financial/bill-to-pay/details.vue"),
  },
  {
    path: "/dashboard/financeiro/pagamentos/:cd/editar",
    name: "financial-bill-to-pay-edit",
    component: () => import("@/views/financial/bill-to-pay/form.vue"),
  },
  {
    path: "/dashboard/financeiro/transferencias/adicionar",
    name: "financial-transfer-add",
    component: () => import("@/views/financial/transfer/form.vue"),
  },
  {
    path: "/dashboard/financeiro/transferencias/:cd",
    name: "financial-transfer-details",
    component: () => import("@/views/financial/transfer/details.vue"),
  },
  {
    path: "/dashboard/financeiro/transferencias/:cd/editar",
    name: "financial-transfer-edit",
    component: () => import("@/views/financial/transfer/form.vue"),
  },
  {
    path: "/dashboard/financeiro/categorias/adicionar",
    name: "financial-category-add",
    component: () => import("@/views/financial/category/form.vue"),
  },
  {
    path: "/dashboard/financeiro/categorias/:cd",
    name: "financial-category-details",
    component: () => import("@/views/financial/category/details.vue"),
  },
  {
    path: "/dashboard/financeiro/categorias/:cd/editar",
    name: "financial-category-edit",
    component: () => import("@/views/financial/category/form.vue"),
  },
  {
    path: "/dashboard/financeiro/contas-bancarias/adicionar",
    name: "financial-bank-account-add",
    component: () => import("@/views/financial/bank-account/form.vue"),
  },
  {
    path: "/dashboard/financeiro/contas-bancarias/:cd",
    name: "financial-bank-account-details",
    component: () => import("@/views/financial/bank-account/details.vue"),
  },
  {
    path: "/dashboard/financeiro/contas-bancarias/:cd/editar",
    name: "financial-bank-account-edit",
    component: () => import("@/views/financial/bank-account/form.vue"),
  },
  {
    path: "/dashboard/financeiro/centros-de-custo/adicionar",
    name: "financial-cost-center-add",
    component: () => import("@/views/financial/cost-center/form.vue"),
  },
  {
    path: "/dashboard/financeiro/centros-de-custo/:cd",
    name: "financial-cost-center-details",
    component: () => import("@/views/financial/cost-center/details.vue"),
  },
  {
    path: "/dashboard/financeiro/centros-de-custo/:cd/editar",
    name: "financial-cost-center-edit",
    component: () => import("@/views/financial/cost-center/form.vue"),
  },
  {
    path: "/dashboard/financeiro/fornecedores/adicionar",
    name: "financial-favored-add",
    component: () => import("@/views/financial/favored/form.vue"),
  },
  {
    path: "/dashboard/financeiro/fornecedores/:cd",
    name: "financial-favored-details",
    component: () => import("@/views/financial/favored/details.vue"),
  },
  {
    path: "/dashboard/financeiro/fornecedores/:cd/editar",
    name: "financial-favored-edit",
    component: () => import("@/views/financial/favored/form.vue"),
  },
];

export default routes;
