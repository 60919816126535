<template>
  <c-form-template class="c-responsible-form" :useFooter="false" :isFlex="true" @submit="$emit('submit')">
    <c-form-field-template width="calc(100% - 220px)">
      <template #label>Nome da equipe</template>
      <p-input-text v-model="nm_equipe" :disabled="!editable" required autofocus />
    </c-form-field-template>

    <c-form-field-template width="220px">
      <template #label>Meta</template>
      <p-input-text v-model="nr_valor_meta" v-money="moneyMask" required autofocus />
    </c-form-field-template>

    <c-form-field-template width="100%">
      <template #label>Participantes</template>
      <p-auto-complete
        v-model="responsibleQuery"
        :suggestions="responsibles"
        class="p-autocomplete"
        field="nm_funcionario"
        :minLength="3"
        multiple
        required
        @complete="searchResponsible"
        @item-select="onResponsibleSelected"
        @item-unselect="onResponsibleUnselected"
      />
    </c-form-field-template>
  </c-form-template>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import CFormTemplate from "@/components/form/form.vue";
import CFormFieldTemplate from "@/components/form/field.vue";
import AutoComplete from "primevue/autocomplete/AutoComplete.vue";

import { User } from "@/store/user/types";
import moneyMask from "@/plugins/vmoney";
import { removeMoneyMask } from "@/utils/mask";

const PAutoComplete: any = AutoComplete;

@Component({ components: { CFormFieldTemplate, CFormTemplate, PAutoComplete } })
export default class CResponsibleForm extends Vue {
  @Prop({ required: true }) readonly props!: any;

  nm_equipe = "";
  nr_valor_meta = "";
  cd_responsaveis: number[] = [];
  responsibleQuery: any = "";

  editable = false;
  moneyMask = moneyMask;

  get responsibles(): User[] {
    return this.$store.state.department.employee.all;
  }

  async searchResponsible(event: any) {
    await this.$store.dispatch("department/employee/search", {
      nm_funcionario: event.query,
    });
  }

  onResponsibleSelected(event: any) {
    if (event.value?.id && !this.cd_responsaveis.includes(event.value.id)) this.cd_responsaveis.push(event.value.id);
  }

  onResponsibleUnselected(event: any) {
    const index = this.cd_responsaveis.indexOf(event.value?.id);
    if (index > -1) {
      this.cd_responsaveis.splice(index, 1);
    }
  }

  getFields() {
    return {
      nm_equipe: this.nm_equipe,
      nr_valor_meta: removeMoneyMask(this.nr_valor_meta),
      cd_responsaveis: this.cd_responsaveis,
      team: this.props?.team,
    };
  }

  reset() {}

  created() {
    this.nm_equipe = this.props?.team?.nm_equipe || "";
    this.nr_valor_meta = this.props?.team?.nr_valor_meta?.toFixed(2) || "";
    this.cd_responsaveis = [...new Set<number>(this.props?.team?.cd_funcionarios.map((item: any) => item.id))] || [];
    this.responsibleQuery =
      this.cd_responsaveis.map((responsibleId: any) =>
        this.props?.team?.cd_funcionarios.find((responsible: any) => responsible.id === responsibleId)
      ) || "";

    this.editable = this.props?.editable ?? true;
  }
}
</script>

<style lang="scss">
.c-responsible-form {
  min-width: 400px;
  max-width: 600px;

  .p-autocomplete {
    .p-autocomplete-multiple-container {
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 8px 0;
      min-height: 120px;
      box-shadow: none !important;

      .p-autocomplete-input-token {
        align-self: flex-end;
        width: 100%;
        padding: 6px;
        border: 1px solid #bbb;
        border-radius: 4px;
        background-color: #fbfbfb;
      }
    }
  }
}
</style>
