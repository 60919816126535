import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ToastServiceMethods } from "primevue/toastservice";

import gojus from "@/services/gojus";
import {
  ModalComponent,
  ModalParams,
  NotificationChanges,
  ToastComponent,
  ToastParams,
  WarningComponent,
} from "@/store/global/types";
import { GojusError } from "@/services/gojus/types";

@Module({ namespaced: true })
export class GlobalModule extends VuexModule {
  pageLoader = true;
  currentTabView = "hearing";

  warning: WarningComponent = { current: { id: 0 }, messages: [] };
  toast: ToastComponent = [];
  notificationChanges: NotificationChanges[] = [];
  modal: ModalComponent = {
    show: false,
    content: { text: "Mensagem", title: "" },
    recuse: {
      text: "Cancelar",
      class: "p-button-text p-button-danger",
      icon: "fas fa-times",
      function: () => {},
    },
    accept: {
      text: "Confirmar",
      icon: "fas fa-check",
      class: "",
      function: () => {},
    },
    props: [],
    options: {
      useFooter: true,
      useHeader: true,
      closable: false,
      useRecuse: true,
      asyncClose: false,
      style: {},
      modal: "default",
      isHTML: false,
    },
  };
  fileUpload = false;
  print = {
    show: false,
    html: "",
  };

  get hasError() {
    for (const message of this.toast) if (message.severity === "error") return true;

    return false;
  }

  get hasToast() {
    return this.toast.length !== 0;
  }

  get hasWarningMessage() {
    return this.warning.current.id !== 0;
  }

  @Mutation
  updateCurrentTabView(tab: string) {
    this.currentTabView = tab;
  }

  @Mutation
  updateFileUpload(status: boolean) {
    this.fileUpload = status;
  }

  @Mutation
  updateToast(items: ToastParams[]) {
    this.toast = items.map((item) => ({
      summary: item.summary,
      detail: item.detail ?? "",
      severity: item.severity,
      life: item.life ?? 4000,
      closable: item.closable ?? false,
    }));
  }

  @Mutation
  showToast(toast: ToastServiceMethods) {
    this.toast.forEach((item) => toast.add(item));
    this.toast = [];
  }

  @Mutation
  updateWarningMessages(messages: any[]) {
    this.warning.messages = messages;
  }

  @Mutation
  updateWarningStatus(status: boolean) {
    this.warning.current.ie_ativo = status;
    this.warning.current = this.warning.messages.find((message) => message.ie_ativo) || { id: 0 };
  }

  @Mutation
  updateLoaderStatus(payload: { status: boolean }) {
    this.pageLoader = payload.status;
  }

  @Mutation
  updateNotificationChanges(changes: NotificationChanges[]) {
    this.notificationChanges = changes;
  }

  @Mutation
  updateModal(payload: ModalParams) {
    this.modal.content.text = payload.content?.text ?? "";
    this.modal.content.title = payload.content?.title ?? "";

    this.modal.accept.text = payload.accept?.text ?? "Confirmar";
    this.modal.accept.icon = payload.accept?.icon ?? "fas fa-check";
    this.modal.accept.class = payload.accept?.class ?? "";
    this.modal.accept.function = (params: any) => {
      if (payload.accept?.function) payload.accept?.function(params);

      if (!this.modal.options.asyncClose) this.modal.show = false;
    };

    this.modal.recuse.text = payload.recuse?.text ?? "Cancelar";
    this.modal.recuse.icon = payload.recuse?.icon ?? "fas fa-times";
    this.modal.recuse.class = payload.recuse?.class ?? "p-button-text p-button-danger";
    this.modal.recuse.function = () => {
      this.modal.show = false;
      if (payload.recuse?.function) payload.recuse.function();
    };

    this.modal.options.modal = payload.options?.modal ?? "default";
    this.modal.options.style = payload.options?.style ?? {};
    this.modal.options.closable = payload.options?.closable ?? false;
    this.modal.options.asyncClose = payload.options?.asyncClose ?? false;
    this.modal.options.useFooter = payload.options?.useFooter ?? true;
    this.modal.options.useHeader = payload.options?.useHeader ?? true;
    this.modal.options.useRecuse = payload.options?.useRecuse ?? true;
    this.modal.options.isHTML = payload.options?.isHTML ?? false;

    this.modal.props = payload.props ?? [];
  }

  @Mutation
  updateModalStatus(show: boolean) {
    this.modal.show = show;
  }

  @Action
  showModal(payload?: ModalParams) {
    if (payload !== undefined) this.context.commit("updateModal", payload);
    this.context.commit("updateModalStatus", true);
  }

  @Action
  async handleError(error: any) {
    if (error.isGojusError) {
      const gojusError: GojusError = error;

      if (!gojusError.isCancel) this.context.commit("updateToast", gojusError.messages);
    } else {
      const { NODE_ENV } = process.env;
      if (NODE_ENV === "development") console.error(error);

      Promise.reject("Erro inesperado");
    }
  }

  @Action
  async getNotificationChanges() {
    try {
      const response = await gojus.get<NotificationChanges[]>({
        entity: "notifications/changes",
        config: { baseAPI: process.env.VUE_APP_API_PROCESS_URL },
      });

      const changes = response?.data || [];
      this.context.commit("updateNotificationChanges", changes);

      return response;
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.error(error);
      // return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default GlobalModule;
