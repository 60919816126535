import { AxiosInstance } from "axios";

import formatGojusError from "@/services/gojus/errors";
import { addQuery, configRequestDefs } from "@/services/gojus/utils";

import store from "@/store";
import { GojusRequestConfig } from "@/services/gojus/types";

async function handleExpirationToken(config: GojusRequestConfig) {
  const TOKEN = localStorage.getItem("TOKEN");
  const TOKEN_DATE = localStorage.getItem("TOKEN_DATE");
  const TOKEN_LOADING = localStorage.getItem("TOKEN_LOADING");

  try {
    if (!TOKEN || config.url?.includes("token/refresh/") || TOKEN_LOADING) return;
    localStorage.setItem("TOKEN_LOADING", "true");

    const currentDate = new Date();
    const timeToRefreshToken = (() => {
      const tokenDate = TOKEN_DATE ? new Date(TOKEN_DATE) : new Date();
      tokenDate.setMinutes(tokenDate.getMinutes() + 10);

      return tokenDate;
    })();

    if (!TOKEN_DATE || currentDate > timeToRefreshToken) {
      await store.dispatch("user/refreshToken");
    }
  } catch (error) {
    if (process.env.NODE_ENV === "development") console.error(error);
  } finally {
    localStorage.removeItem("TOKEN_LOADING");
  }
}

export function configResponse(instance: AxiosInstance) {
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const gojusError = error.config?.useErrorFormatter !== false ? await formatGojusError(error) : error.response;
      return Promise.reject(gojusError);
    }
  );
}

export function configRequest(instance: AxiosInstance) {
  instance.interceptors.request.use(
    (config: GojusRequestConfig) => {
      setTimeout(() => {
        handleExpirationToken(config);
      }, (Math.floor(Math.random() * 1000) + 1) * 100);

      const storeUsername = (store.state as any)?.user?.current?.username;
      const localStorageUsername = localStorage.getItem("USERNAME");

      if (storeUsername && localStorageUsername && storeUsername !== localStorageUsername) {
        store.commit("user/updateSessionState", { active: false });
      }

      if (config.query) return addQuery(configRequestDefs(config));
      return configRequestDefs(config) as any;
    },
    (error) => {
      const DEBUG = process.env.NODE_ENV === "development";
      if (DEBUG) console.error(error);

      return Promise.reject(error);
    }
  );
}

export function configInterceptors(instance: AxiosInstance) {
  configRequest(instance);
  configResponse(instance);

  return instance;
}

export default configInterceptors;
