import { MenubarComponent } from "@/store/user/types";

const menus: MenubarComponent = [
  {
    label: "Analytics",
    icon: "fas fa-chart-bar",
    class: "cd-46 analytics",
    to: { name: "analytics" },
    permission: "menu/analytics:::get",
  },
  {
    label: "Processos",
    icon: "fas fa-gavel",
    class: "cd-47 process",
    to: { name: "process" },
    permission: "menu/processos:::get",
  },
  {
    label: "Jurídico",
    icon: "fas fa-chalkboard-teacher",
    class: "cd-10",
    items: [
      {
        label: "Clientes",
        icon: "fas fa-user",
        class: "cd-47 lawsuit-customer sub-10",
        to: { name: "lawsuit-customer" },
        permission: "menu/juridico/clientes:::get",
      },
      {
        label: "Teses",
        icon: "fas fa-book-open-reader",
        class: "cd-20 theses-bank sub-10",
        to: { name: "juridical-theses-bank" },
        permission: "menu/teses/teses:::get",
      },
      {
        label: "Foros",
        icon: "fas fa-landmark",
        class: "cd-7 forum sub-10",
        to: { name: "forum" },
        permission: "menu/juridico/foros:::get",
      },
      {
        label: "Áreas",
        icon: "fas fa-network-wired",
        class: "cd-17 areas sub-10",
        to: { name: "areas" },
        permission: "menu/juridico/areas:::get",
      },
      {
        label: "Instâncias",
        icon: "fas fa-people-arrows",
        class: "cd-18 instancia sub-10",
        to: { name: "instancia" },
        permission: "menu/juridico/instancias:::get",
      },
      {
        label: "Fases",
        icon: "fas fa-layer-group",
        class: "cd-20 fase sub-10",
        to: { name: "fase" },
        permission: "menu/juridico/fases:::get",
      },
      {
        label: "Tipos de Ação",
        icon: "fas fa-location-crosshairs",
        class: "cd-20 action-type sub-10",
        to: { name: "juridical-action-type" },
        permission: "menu/teses/tipo-acao:::get",
      },
      {
        label: "Causas da Ação",
        icon: "fas fa-suitcase",
        class: "cd-20 action-cause sub-10",
        to: { name: "juridical-action-cause" },
        permission: "menu/teses/causa-acao:::get",
      },
    ],
  },
  {
    label: "Integração",
    icon: "fas fa-network-wired",
    class: "cd-99 integration",
    permission: "menu/integracao:::get",
    to: { name: "integration" },
  },
  {
    label: "Logística",
    icon: "fas fa-user-friends",
    class: "cd-13 logistica",
    items: [
      {
        label: "Audiências",
        icon: "fas fa-balance-scale",
        class: "cd-1 audiencia sub-13",
        to: { name: "audiencia" },
        permission: "menu/logistica/audiencias:::get",
      },
      {
        label: "Diligências",
        icon: "far fa-handshake",
        class: "cd-2 diligencia sub-13",
        to: { name: "diligencia" },
        permission: "menu/logistica/diligencias:::get",
      },
      {
        label: "Clientes",
        icon: "fas fa-user",
        class: "cd-5 logistics-customer sub-13",
        to: { name: "logistics-customer" },
        permission: "menu/logistica/clientes:::get",
      },
      {
        label: "Correspondentes",
        icon: "fas fa-user-tie",
        class: "cd-4 logistics-correspondent sub-13",
        to: { name: "logistics-correspondent" },
        permission: "menu/logistica/correspondentes:::get",
      },
      {
        label: "Tipos",
        icon: "fas fa-list",
        class: "cd-14 logistics-acttype sub-13",
        to: { name: "logistics-acttype" },
        permission: "menu/logistica/tipos:::get",
      },
    ],
  },
  {
    label: "Solicitações",
    icon: "fas fa-file-contract",
    class: "cd-38 requests",
    to: { name: "requests" },
    permission: "menu/solicitacoes:::get",
  },
  {
    label: "Honorários",
    icon: "fas fa-calendar",
    class: "cd-38 requests",
    to: { name: "honorary" },
    permission: "menu/honorarios:::get",
  },
  {
    label: "Cobrança",
    icon: "fas fa-hand-holding-usd",
    class: "cd-48 charge",
    items: [
      {
        label: "Devedores",
        icon: "fas fa-book-dead",
        class: "cd-35 debtor sub-48",
        to: { name: "debtor" },
        permission: "menu/cobranca/devedores:::get",
      },
      {
        label: "Clientes",
        icon: "fas fa-user",
        class: "cd-67 collection-customer sub-48",
        to: { name: "collection-customer" },
        permission: "menu/cobranca/clientes:::get",
      },
      {
        label: "Tipos de débito",
        icon: "fas fa-question-circle",
        class: "cd-59 collection-debit-types sub-48",
        to: { name: "collection-debit-types" },
        permission: "menu/cobranca/tipos_debito:::get",
      },
      {
        label: "Regra de cobrança",
        icon: "fas fa-weight",
        class: "cd-61 collection-rule sub-48",
        to: { name: "collection-rule" },
        permission: "menu/cobranca/regra_cobranca:::get",
      },
    ],
  },
  {
    label: "Financeiro",
    icon: "fas fa-cash-register",
    class: "cd-49",
    items: [
      {
        label: "Geral",
        icon: "fas fa-house",
        class: "financial sub-49",
        to: { name: "financial" },
        permission: "menu/financeiro:::get",
      },
      {
        label: "Fornecedores",
        icon: "fas fa-id-card",
        class: "financial-favored sub-49",
        to: { name: "financial-favored" },
        permission: "menu/financeiro:::get",
      },
      {
        label: "Categorias",
        icon: "fas fa-filter-circle-dollar",
        class: "financial-category sub-49",
        to: { name: "financial-category" },
        permission: "menu/financeiro:::get",
      },
      {
        label: "Contas bancárias",
        icon: "fas fa-building-columns",
        class: "financial-bank-account sub-49",
        to: { name: "financial-bank-account" },
        permission: "menu/financeiro:::get",
      },
      {
        label: "Setor",
        icon: "fas fa-circle-dollar-to-slot",
        class: "financial-cost-center sub-49",
        to: { name: "financial-cost-center" },
        permission: "menu/financeiro:::get",
      },
    ],
  },
  {
    label: "Tarefas",
    icon: "fas fa-tasks",
    class: "cd-66 task",
    to: { name: "task" },
    permission: "menu/tarefas:::get",
  },
  {
    label: "Pessoas",
    icon: "fas fa-people-group",
    class: "cd-62 rh",
    items: [
      {
        label: "Funções",
        icon: "fas fa-briefcase",
        class: "cd-63 occupation sub-62",
        to: { name: "occupation" },
        permission: "menu/pessoas/funcoes:::get",
      },
      {
        label: "Funcionários",
        icon: "fas fa-building-user",
        class: "cd-64 employee sub-62",
        to: { name: "employee" },
        permission: "menu/pessoas/funcionarios:::get",
      },
      {
        label: "Locais de Trabalho",
        icon: "fas fa-building",
        class: "cd-65 workplace sub-62",
        to: { name: "workplace" },
        permission: "menu/pessoas/locais_trabalho:::get",
      },
      {
        label: "Equipes",
        icon: "fas fa-users",
        class: "cd-44 groups sub-62",
        to: { name: "groups-responsible" },
        permission: "menu/pessoas/equipes:::get",
      },
    ],
  },
  {
    label: "Relatórios",
    icon: "fas fa-file-download",
    class: "cd-88 report",
    items: [
      {
        label: "Honorários",
        icon: "fas fa-money-check-alt",
        class: "cd-90 collection-honorarios sub-88",
        to: { name: "command:::getCollectionFees" },
        permission: "menu/relatorios/honorarios:::get",
      },
      {
        label: "Acordos",
        icon: "fas fa-handshake",
        class: "cd-91 collection-agreements sub-88",
        to: { name: "command:::getCollectionAgreement" },
        permission: "menu/relatorios/acordos:::get",
      },
      {
        label: "Cobrança Geral",
        icon: "fas fa-hand-holding-dollar",
        class: "cd-91 collection-agreements sub-88",
        to: { name: "command:::getCollectionGeneral" },
        permission: "menu/relatorios/cobranca_geral:::get",
      },
      {
        label: "Somente Audiências",
        icon: "fas fa-balance-scale",
        class: "cd-101 logistics-hearing sub-88",
        to: { name: "command:::getCompletedRequestHearing" },
        permission: "menu/relatorios/somente_audiencias:::get",
      },
      {
        label: "Somente Diligências",
        icon: "far fa-handshake",
        class: "cd-100 logistics-diligence sub-88",
        to: { name: "command:::getCompletedRequestDiligence" },
        permission: "menu/relatorios/somente_diligencias:::get",
      },
      {
        label: "Audiências e Diligências",
        icon: "fas fa-gavel",
        class: "cd-100 logistics-hearing sub-88",
        to: { name: "command:::getCompletedAllRequest" },
        permission: "menu/relatorios/todas_solicitacoes:::get",
      },
    ],
  },
  {
    label: "Midgard",
    icon: "fas fa-tools",
    class: "cd-41 midgard",
    url: process.env.VUE_APP_MIDGARD,
    permission: "menu/midgard:::get",
  },
];

export default menus;
