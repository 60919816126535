import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import gojus from "@/services/gojus";

import IntegrationPublicationModule from "@/store/integration/publication/module";
import IntegrationFoundModule from "./found/module";
import IntegrationMovementModule from "./movement/module";
import { IntegrationSystemT } from "./types";

const { VUE_APP_API_PROCESS_URL } = process.env;

@Module({ namespaced: true })
class IntegrationModule extends VuexModule {
  systems: IntegrationSystemT[] = [];
  attorneys: any[] = [];

  static modules = {
    publication: IntegrationPublicationModule,
    found: IntegrationFoundModule,
    movement: IntegrationMovementModule,
  };

  @Mutation
  setAttorneys(attorneys: any[]) {
    this.attorneys = attorneys;
  }

  @Mutation
  setSystems(systems: IntegrationSystemT[]) {
    this.systems = systems;
  }

  @Action
  async getSystems(payload?: { filters?: Record<string, any> }) {
    try {
      const response = await gojus.get<any[]>({
        entity: "/systems",
        config: { query: { ...payload?.filters }, baseAPI: VUE_APP_API_PROCESS_URL },
      });

      this.context.commit("setSystems", response.data);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAttorneys() {
    try {
      const response = await gojus.get<any[]>({
        entity: "/attorney",
        config: { query: {}, baseAPI: VUE_APP_API_PROCESS_URL },
      });

      this.context.commit("setAttorneys", response.data);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async createAttorney(payload: { form: any }) {
    try {
      const response = await gojus.post({
        entity: "/attorney",
        data: payload.form,
        config: { query: {}, baseAPI: VUE_APP_API_PROCESS_URL },
      });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async getAttorneyAccount(payload: { attorneyId: number; accountId: number }) {
    try {
      const response = await gojus.get<any>({
        entity: `/attorney/${payload.attorneyId}`,
        config: { query: {}, baseAPI: VUE_APP_API_PROCESS_URL },
      });

      const account = response.data?.accounts?.find((item: any) => item.id === payload.accountId);

      return account || null;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async createAttorneyAccount(payload: { form: any }) {
    try {
      const response = await gojus.post({
        entity: "/account",
        data: payload.form,
        config: { query: {}, baseAPI: VUE_APP_API_PROCESS_URL },
      });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async editAttorneyAccount(payload: { cd: number; form: any }) {
    try {
      const response = await gojus.patch({
        entity: `/account/${payload.cd}`,
        data: payload.form,
        config: { query: {}, baseAPI: VUE_APP_API_PROCESS_URL },
      });

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default IntegrationModule;
