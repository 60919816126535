import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ToastParams } from "../global/types";
import ExpedientAttorneyModule from "./attorney/module";
import { ExpedientService } from "./service";
import { Expedient, ExpedientForm, ExpedientSummary } from "./types";

@Module({ namespaced: true })
export class ExpedientModule extends VuexModule {
  all: any = [];
  summary: ExpedientSummary = {
    fatal_dia: 0,
    lidos_dia: 0,
    nao_lidos: 0,
    pendente_ciencia: 0,
    prazo_findado: 0,
    sem_prazo: 0,
    ultimo_dia_ciencia: 0,
  };

  summaryFilter = "";
  filters: any = { attorney_id: null };

  service = new ExpedientService();

  static modules = {
    attorney: ExpedientAttorneyModule,
  };

  @Mutation
  updateFilters(value: any) {
    this.filters = value || { attorney_id: null };
    if (this.summaryFilter) this.filters.resumo = this.summaryFilter;
  }

  @Mutation
  updateAttorneyFilter(value: any) {
    this.filters.attorney_id = value;
  }

  @Mutation
  updateSummaryFilter(value: any) {
    this.summaryFilter = value;
    this.filters.resumo = value;
  }

  @Mutation
  updateSummary(taskSummary: any) {
    for (const key in taskSummary) {
      const newKey = key.replaceAll("-", "_").toLocaleLowerCase();
      taskSummary[newKey] = taskSummary[key];

      delete taskSummary[key];
    }

    this.summary = taskSummary;
  }

  @Mutation
  updateExpedients(expedients: Expedient[]) {
    this.all = expedients;
  }

  @Action
  async getReportExcel(params: any) {
    const response = await this.service.getReportExcel({ ...this.filters, ...params?.filters });

    return response;
  }

  @Action({ rawError: true })
  async getAll(payload?: { filters?: any; commit?: boolean }) {
    const response = await this.service.getAll({ ...this.filters, ...payload?.filters });
    const expedients = response.data || {};

    if (payload?.commit === false) {
      return expedients;
    }

    this.context.commit("updateExpedients", expedients);
    this.context.dispatch("getSummary");

    return expedients;
  }

  @Action
  async getSummary() {
    try {
      const filters: any = {};
      if (this.filters.attorney_id) filters.attorney_id = this.filters.attorney_id;

      const response = await this.service.getSummary(filters);
      this.context.commit("updateSummary", response);

      return response;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }

  @Action
  async edit(params: ExpedientForm) {
    try {
      const data = await this.service.edit(params.cd, params.form);
      this.context.commit("updateCurrent", data);

      const message: ToastParams[] = [{ severity: "success", summary: "Expediente editado com sucesso!" }];
      this.context.commit("global/updateToast", message, { root: true });

      return data;
    } catch (error) {
      return this.context.dispatch("global/handleError", error, { root: true });
    }
  }
}

export default ExpedientModule;
